import React, { useState, useEffect } from 'react'
import './Home.css'
import waterlion from '../assets/waterliongold.jpeg';
import venmo from '../assets/venmo.png'
import scrollTop from '../assets/scroll.svg'
import ReactDOM from "react-dom"
import { PayPalButton } from "react-paypal-button-v2";

export default function Home() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        reSizeVH();
        window.addEventListener('resize', () => {
            reSizeVH()
        });

        window.addEventListener('orientationchange', () => {
            reSizeVH()
        });

        document.addEventListener('scroll', toggleVisibility, true)

    }, []);

    const toggleVisibility = () => {
        var element = document.getElementsByClassName('section2');
        var element2 = document.getElementsByClassName('section4');

        if (checkVisible(element[0]) || checkVisible(element2[0])) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    function reSizeVH() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    function scrollToTop() {
        var element = document.getElementsByClassName('section1');
        element[0].scrollIntoView({ behavior: "smooth" });
    }
    function scrollToAbout() {
        var element = document.getElementsByClassName('section2');
        element[0].scrollIntoView({ behavior: "smooth" });
    }
    function scrollToContact() {
        var element = document.getElementsByClassName('section3');
        element[0].scrollIntoView({ behavior: "smooth" });
    }
    function scrollToDonate() {
        var element = document.getElementsByClassName('section4');
        element[0].scrollIntoView({ behavior: "smooth" });
    }
    function checkVisible(elm) {
        var rect = elm.getBoundingClientRect();
        var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
        return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
    }

    return (
        <div className="container">
            {isVisible &&
                <div className="scroll-top" onClick={() => scrollToTop()}>
                    <img className="scroll-top" src={scrollTop} alt='Go to top' />
                </div>
            }

            <section className="section1">

                <div className="center-section">
                    <img alt="Loudmouth Logo" className="logo-image" height="300" src={waterlion} />
                    <p className="title">LOUDMOUTH MINISTRIES</p>
                    {/* <p style={{ fontSize: 18, fontStyle: 'italic' }}>Site under construction.</p> */}
                </div>
                <div className="big-nav">
                    <p className="nav-text" onClick={() => scrollToAbout()}>About</p>
                    <p className="nav-text" onClick={() => scrollToContact()}>Contact</p>
                    <p className="nav-text" onClick={() => scrollToDonate()}>Donate</p>
                </div>
            </section>
            <section className="section2">
                <div className="center-section black">

                    <p className="title">About Us</p>
                    <p className="about">Loudmouth Ministries purpose is to S.E.L.L. Serve, Equip, & Live Loud. We quickly want to clarify that we don’t sell Jesus, and people aren’t practice. If we talk people into Jesus, then the world can just talk them out of Him. Our goal is to simply introduce them to a Man that completely changed our life and we are radically in love with and His name is Jesus. The local church matters, we travel the country partnering with local churches reminding sons and daughters who they are in Christ so they manifest the glory of God through family and community. We invade the streets of earth with Heaven everywhere we go! We train and equip leaders to help others step out in faith to share Jesus boldly to the ends of the world. We go by the model Jesus gave us. Jesus is King, people are worth it, & we live in servanthood leadership. Serve humbly, equip well, and live loud!</p>
                    <p className="about">“For whosoever shall call upon the name of the Lord shall be saved. How then shall they call on him in whom they have not believed? and how shall they believe in him of whom they have not heard? and how shall they hear without a preacher? And how shall they preach, except they be sent? as it is written, How beautiful are the feet of them that preach the gospel of peace, and bring glad tidings of good things! But they have not all obeyed the gospel. For Esaias saith, Lord, who hath believed our report? So then faith cometh by hearing, and hearing by the word of God. But I say, Have they not heard? Yes verily, Their sound went into all the earth, and their words unto the ends of the world.” Romans‬ ‭10:13-18</p>
                    <p className="about">We believe Jesus was moved with compassion and we should look just like Him. Without Holy Spirit we have no compassion to offer. We believe that true ministry only comes through an abundance of Him! We must be overflowing with Him, before we can assist anyone else in their abundance in Him. You can only give away what you have or it’s counterfeit. You can only lead as far as you’ve gone, or it’s exploring. If you get to the top of the mountain and you’re all alone, you’re not a leader, you’re a hiker. Displaying the heart of the Father is getting there together. Loudmouth Ministries is committed to serve, and equip the body of Christ in pressing beyond. So, we have one simple question for you. Are you ready to live a Loudmouth Life for Him?</p>
                </div>
            </section>
            <section className="section3">
                <div className="center-section">
                    <p className="title">Contact Us</p>
                    <p className="email">609 Colts Neck Ct.</p>
                    <p className="email">Colleyville, TX 76034</p>
                    <p className="email">(615) 248-2646</p>
                    <p className="email">LOUDMOUTHMINISTRIES@GMAIL.COM</p>
                    <a href="https://twitter.com/loudmouth_eth" class="twitter-follow-button" data-show-count="false">Follow @loudmouthlion</a>
                </div>
            </section>
            <section className="section4">
                <div className="center-section black">
                    <p className="title">Donate</p>
                    <img alt="Donate via Venmo" style={{ borderRadius: 25 }} height="500" src={venmo}></img>
                    {/* <PayPalButton
                        amount="0.01"
                        // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                        onSuccess={(details, data) =>
                        {
                            alert("Transaction completed by " + details.payer.name.given_name);

                            // OPTIONAL: Call your server to save the transaction
                            return fetch("/paypal-transaction-complete", {
                                method: "post",
                                body: JSON.stringify({
                                    orderId: data.orderID
                                })
                            });
                        }}
                        options={{
                            clientId: "AXRYkNYeXsDGbE9nSfyANBomp51QSUTY-7JxYO-WMkq4EkR_bEsx33Hugcftm7HITxDrW2ZugYvoZemt",
                            "enable-funding": 'venmo'
                        }}
                    /> */}
                </div>
            </section>
        </div>
    )
}
